
import Vue from "vue";
import { Trip, TripCardComponentProps } from "@/shared/models";
export default Vue.extend({
  name: "TripSummaryItem",
  props: {
    tripDetail: Object as () => Trip | undefined,
    selectedCard: {
      type: String,
      default: "undefined"
    }
  },
  computed: {
    isSelected(): boolean {
      if (this.selectedCard == this.tripDetail?.id?.toString()) return true;
      else return false;
    }
  }
});
