var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar-right is-hidden-mobile"},[(_vm.bookingDetail)?_c('div',[_c('div',{staticClass:"p-5"},[_c('div',[_c('div',{staticClass:"columns mb-0"},[_c('div',{staticClass:"column pb-0"},[_c('p',{staticClass:"is-size-5"},[_vm._v(" "+_vm._s(_vm.bookingDetail.bookingNo)+" ")])]),_c('div',{staticClass:"column is-narrow pb-0"},[_c('button',{staticClass:"button button-round",on:{"click":() => {
                  this.$emit('sidebar-closed');
                }}},[_c('i',{staticClass:"mdi mdi-close"})])])]),_c('div',{staticClass:"columns mb-0"},[_c('div',{staticClass:"column pb-0"},[_c('p',{staticClass:"text-small"},[_vm._v(" Added on "+_vm._s(_vm._f("date")(_vm.bookingDetail.createdAt,"date-time-seconds"))+" ")]),_c('p',{staticClass:"text-small"},[_vm._v(" Last updated on "+_vm._s(_vm._f("date")(_vm.bookingDetail.updatedAt,"date-time-seconds"))+" ")])]),_c('div',{staticClass:"column is-narrow pb-0 mt-3"},[_c('div',[_c('a',{staticClass:"has-text-info",on:{"click":() => {
                    this.$emit('edit-booking');
                  }}},[_c('i',{staticClass:"mdi mdi-pencil pr-1 is-danger"})]),_c('a',{staticClass:"has-text-danger",on:{"click":() => {
                    this.$emit('delete-booking');
                  }}},[_c('i',{staticClass:"mdi mdi-delete pr-1"})])])])])]),_c('hr'),_c('div',[_c('p',{staticClass:"text-small mt-4"},[_vm._v("Start Time")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.bookingDetail.startTime,"date-time-seconds"))+" ")]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("End Date")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.bookingDetail.endTime,"date-time-seconds"))+" ")]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("Vehicle")]),_c('div',{staticClass:"columns mt-1"},[_c('div',{staticClass:"column"},[_c('p',[_vm._v(_vm._s(_vm.bookingDetail.vehicle.nickname))]),_c('p',{staticClass:"text-small"},[_vm._v(" "+_vm._s(_vm.bookingDetail.vehicle.make)+" - "+_vm._s(_vm.bookingDetail.vehicle.model)+" ")])]),_c('div',{staticClass:"column is-narrow"},[_c('b-tag',{staticClass:"is-info",attrs:{"rounded":""}},[_vm._v(" "+_vm._s(_vm.bookingDetail.vehicle.registrationNo)+" ")])],1)]),_c('p',{staticClass:"text-small mt-4"},[_vm._v(" Linked Jobs "),(_vm.bookingDetail.jobs && _vm.bookingDetail.jobs.length)?_c('span',[_vm._v(" ("+_vm._s(_vm.bookingDetail.jobs.length)+") ")]):_vm._e()]),(_vm.bookingDetail.jobs.length)?_c('div',{staticClass:"mt-2"},_vm._l((_vm.bookingDetail.jobs),function(job){return _c('div',{key:job.id},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(job.title)+" ")]),_c('div',{staticClass:"column is-narrow"},[_c('b-tag',{attrs:{"rounded":""}},[_vm._v(" "+_vm._s(job.jobNo))])],1)])])}),0):_c('div',[_c('p',[_vm._v("-")])]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("Assigned to")]),_c('p',[_vm._v(" "+_vm._s(_vm.bookingDetail.assignee.firstName + " " + _vm.bookingDetail.assignee.lastName)+" ")]),_c('p',{staticClass:"text-small mt-4 pb-1"},[_vm._v("Comment")]),_c('p',[_vm._v(_vm._s(_vm._f("formatNullvalue")(_vm.bookingDetail.comment)))])])])]):_c('div',[_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"hero-body has-text-centered sidebar-right-empty"},[_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require("@/assets/img/sidebar-right-idle-icon.svg")}}),_c('p',[_vm._v("Select a booking to view details")])])])])
}]

export { render, staticRenderFns }