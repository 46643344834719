
import Vue from "vue";
import api from "@/services/dataService";
import notification from "@/services/notificationService";
import router from "@/router";
import reject from "lodash/reject";
import orderBy from "lodash/orderBy";
import find from "lodash/find";
import { Booking, Vehicle, JobStatus, User, Job } from "@/shared/models";
import BookingsRightSidebar from "@/components/BookingsRightSidebar.vue";
import { DateTime } from "luxon";
import { filter } from "vue/types/umd";
export default Vue.extend({
  name: "Bookings",
  components: { BookingsRightSidebar },
  data() {
    return {
      JobStatus,
      today: new Date(),
      tableProps: {
        data: [],
        loading: false,
        total: 0,
        page: 1,
        perPage: (window.innerHeight / 66).toFixed(0) // Calculating rows per page according to screen height
      },
      selected: undefined as undefined | Booking,
      filteredVehicles: undefined as undefined | Vehicle[],
      filteredAssignee: undefined as undefined | User[],
      filters: {
        dateRangeStart: undefined as undefined | Date,
        dateRangeEnd: undefined as undefined | Date,
        vehicles: [] as string[],
        assignee: [] as string[],
        category: [] as string[],
        searchQuery: undefined as undefined | string,
        vehicleSearchQuery: undefined as undefined | string,
        assigneeSearchQuery: undefined as undefined | string,
        jobsSearchQuery: undefined as undefined | string,
        sort: "-startTime"
      },
      modal: {
        filteredVehicles: undefined as undefined | Vehicle[],
        filteredJobs: undefined as undefined | Job[],
        filteredAssignee: undefined as undefined | User[]
      },
      modalBookingDetail: {
        startTime: undefined as undefined | Date,
        endTime: undefined as undefined | Date,
        comment: undefined as undefined | string,
        isCancelled: false,
        vehicle: undefined as undefined | Vehicle,
        jobs: [] as Job[],
        assignee: undefined as undefined | User
      },
      editBookingDetail: {
        id: undefined as undefined | string
      },
      editBookingToggle: false,
      addBookingToggle: false,
      deleteBookingToggle: false
    };
  },
  computed: {
    bookings() {
      return this.$store.state.bookings;
    },
    vehicles(): undefined | Vehicle[] {
      return this.$store.state.vehicles;
    },
    assignee(): undefined | User[] {
      return this.$store.state.users;
    },
    jobs(): undefined | Job[] {
      return this.$store.state.jobs;
    }
  },
  watch: {
    bookings: function() {
      if (this.$route.query.id && this.bookings) {
        const selectedBooking = find(this.bookings.items, {
          id: this.$route.query.id
        } as Booking);
        // To do: Use of any to be changed on lodash update
        this.selected = selectedBooking as Booking;
      }
    }
  },
  created() {
    if (this.$route.query.showVehiclesId) {
      this.filters.vehicles = this.$route.query.showVehiclesId
        .toString()
        .split(",");
    }
    if (this.$route.query.assignee) {
      this.filters.vehicles = this.$route.query.assignee.toString().split(",");
    }
    if (this.$route.query.startDate) {
      this.filters.dateRangeStart = new Date(
        this.$route.query.startDate.toString()
      );
    }
    if (this.$route.query.endDate) {
      this.filters.dateRangeEnd = new Date(
        this.$route.query.endDate.toString()
      );
    }
    if (this.$route.query.sort) {
      this.filters.sort = this.$route.query.sort.toString();
    }
    this.getBookings();
    this.$store.dispatch("getVehicles");
    this.$store.dispatch("getUsers");
    this.$store.dispatch("getJobs");
  },
  methods: {
    //Handle page-change event
    onPageChange(page: number) {
      this.tableProps.page = page;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page.toString()
        }
      });
      this.getBookings();
    },
    openDeleteBookingModal() {
      this.deleteBookingToggle = true;
    },
    openAddBookingModal() {
      this.addBookingToggle = true;
      this.modal.filteredJobs = this.jobs;
      this.modal.filteredVehicles = this.vehicles;
      this.modal.filteredAssignee = this.assignee;
      this.modalBookingDetail = {
        startTime: undefined,
        endTime: undefined,
        comment: undefined,
        isCancelled: false,
        vehicle: undefined,
        jobs: [],
        assignee: undefined
      };
      this.removeSelection();
    },
    openEditBookingModal() {
      this.editBookingToggle = true;
      this.modal.filteredJobs = this.jobs;
      this.modal.filteredVehicles = this.vehicles;
      this.modal.filteredAssignee = this.assignee;
      this.editBookingDetail.id = this.selected?.id;
      this.modalBookingDetail = {
        startTime: new Date(this.selected!.startTime),
        endTime: new Date(this.selected!.endTime),
        comment: this.selected?.comment,
        isCancelled: false,
        vehicle: this.selected?.vehicle,
        jobs: this.selected!.jobs,
        assignee: this.selected?.assignee
      };
      this.removeSelection();
    },
    filterBookings() {
      this.tableProps.page = 1;
      if (this.filters.dateRangeEnd) {
        // Prepare endDate with 23:59 time for request
        this.filters.dateRangeEnd.setHours(23, 59);
      }
      // adding selected filters as query
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          startDate: this.filters.dateRangeStart?.toISOString(),
          endDate: this.filters.dateRangeEnd?.toISOString(),
          sort: this.filters.sort,
          showVehiclesId: this.filters.vehicles.toString(),
          assignee: this.filters.assignee.toString()
        }
      });
      this.getBookings();
    },
    showDetail(payload: Booking) {
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: this.selected?.id
        }
      });
    },
    removeSelection() {
      this.selected = undefined;
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: undefined
        }
      });
    },
    filterVehicles(query: string, isModal = false) {
      let list = this.vehicles;
      if (query != "") {
        list = list?.filter(vehicles => {
          return (
            vehicles.registrationNo
              ?.toLowerCase()
              .includes(query.toLowerCase()) ||
            vehicles.nickname?.toLowerCase().includes(query.toLowerCase())
          );
        });
      }
      if (isModal) {
        this.modal.filteredVehicles = list;
      } else {
        this.filteredVehicles = list;
      }
    },
    filterAssignee(query: string, isModal = false) {
      let list = this.assignee;
      if (query != "") {
        list = list?.filter(user => {
          return (
            user.firstName?.toLowerCase().includes(query.toLowerCase()) ||
            user.lastName?.toLowerCase().includes(query.toLowerCase())
          );
        });
      }
      if (isModal) {
        this.modal.filteredAssignee = list;
      } else {
        this.filteredAssignee = list;
      }
    },
    filterJobs(query: string, isModal = false) {
      let list = this.jobs;
      if (query != "") {
        list = list?.filter(job => {
          return (
            job.title?.toLowerCase().includes(query.toLowerCase()) ||
            job.jobNo?.toLowerCase().includes(query.toLowerCase())
          );
        });
      }
      if (isModal) {
        this.modal.filteredJobs = list;
      }
    },
    clearVehicleFilter() {
      this.filters.vehicles = [];
      this.filterBookings();
    },
    clearAssigneeFilter() {
      this.filters.assignee = [];
      this.filterBookings();
    },
    clearDateRangeFilter() {
      this.filters.dateRangeStart = undefined;
      this.filters.dateRangeEnd = undefined;
      this.filterBookings();
    },
    clearVehicleSearchQuery() {
      this.filters.vehicleSearchQuery = "";
      this.filterVehicles(this.filters.vehicleSearchQuery);
    },
    clearAssigneeSearchQuery() {
      this.filters.assigneeSearchQuery = "";
      this.filterAssignee(this.filters.assigneeSearchQuery);
    },
    async getBookings() {
      this.tableProps.loading = true;
      const request = {
        page: this.tableProps.page,
        sort: this.filters.sort,
        startDate: this.filters.dateRangeStart,
        endDate: this.filters.dateRangeEnd,
        showVehiclesId: this.filters.vehicles.toString(),
        category: this.filters.category.toString(),
        limit: this.tableProps.perPage
      };
      await this.$store.dispatch("getBookings", request);
      if (this.bookings) {
        this.tableProps.total = this.bookings.totalItems;
        this.tableProps.data = this.bookings.items;
        this.tableProps.loading = false;
      }
    },
    validateBooking() {
      if (!this.modalBookingDetail.vehicle) {
        notification.warning("Please select a vehicle");
        return false;
      } else if (!this.modalBookingDetail.assignee) {
        notification.warning("Please select an assignee");
        return false;
      } else if (this.modalBookingDetail.jobs.length === 0) {
        notification.warning("Please link jobs to the booking");
        return false;
      } else if (!this.modalBookingDetail.startTime) {
        notification.warning("Please enter start time");
        return false;
      } else if (!this.modalBookingDetail.endTime) {
        notification.warning("Please enter end time");
        return false;
      } else {
        return true;
      }
    },
    addBooking() {
      if (this.validateBooking()) {
        this.addBookingToggle = false;
        this.$store.dispatch("addBooking", this.modalBookingDetail).then(() => {
          this.getBookings();
        });
      }
    },
    editBooking() {
      if (this.validateBooking()) {
        this.editBookingToggle = false;
        this.$store
          .dispatch("updateBooking", {
            id: this.editBookingDetail.id,
            booking: this.modalBookingDetail
          })
          .then(() => {
            this.getBookings();
          });
      }
    },
    deleteBooking() {
      this.deleteBookingToggle = false;
      this.$store.dispatch("deleteBooking", this.selected?.id).then(() => {
        this.getBookings();
        this.removeSelection();
      });
    }
  }
});
