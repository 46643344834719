
import Vue from "vue";
import { Booking } from "@/shared/models";
import { DateTime } from "luxon";
import api from "@/services/dataService";
import notification from "@/services/notificationService";
export default Vue.extend({
  name: "BookingsRightSidebar",
  props: {
    bookingDetail: Object
  },
  data() {
    return {
      DateTime: DateTime,
      editBookingToggle: false
    };
  }
});
