
import Vue from "vue";
import find from "lodash/find";
import lodash, { capitalize, filter, isEmpty } from "lodash";
import gmapsInit from "@/services/mapService";
import notification from "@/services/notificationService";
import { Vehicle, RouteHistoryMode, behaveId } from "@/shared/models";
import TripSummaryItem from "@/components/TripSummaryItem.vue";
import { DateTime, Duration } from "luxon";
import { helpers } from "@/services/common";
import jsonexport from "jsonexport";

export default Vue.extend({
  name: "RouteHistory",
  components: { TripSummaryItem },
  data() {
    return {
      lodash,
      lodashFilter: filter,
      find,
      Duration,
      RouteHistoryMode,
      routeHistoryMode: RouteHistoryMode.RAW as RouteHistoryMode,
      map: undefined as undefined | any,
      pathColors: {
        pathColorActive: "#000", // black
        pathBorderColorActive: "#959595", // dark grey
        pathColorInactive: "#b5b5b5",
        pathBorderColorInactive: "#828282",
        pathColorUnfiltered: "#ffb87a",
        pathBorderColorUnfiltered: "#f57f25"
      },
      filters: {
        vehicleSearchQuery: undefined as undefined | string
      },
      filteredVehicles: undefined as undefined | Vehicle[],
      query: {
        date: undefined as undefined | Date,
        selectedVehicle: undefined as undefined | string,
        removeGpsNoise: false
      },
      isRouteLoading: false,
      selectedTrip: undefined as undefined | string,
      tripPaths: [] as any[],
      tripReplay: {
        animationActive: false,
        playbackInterval: 1000, // value in milliseconds,
        animationIterator: -1, // for keeping track of current state of animation w.r.t. events
        vehicleMarker: undefined as undefined | any,
        backgroundRefresh: undefined as undefined | NodeJS.Timeout,
        animationComplete: false
      },
      tableProps: {
        isPaginated: false,
        isPaginationSimple: false,
        paginationPosition: "bottom",
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small",
        currentPage: 1,
        perPage: 10
      },
      selected: undefined,
      rawEventInfoWindow: undefined as undefined | any,
      rawEventMarker: undefined as undefined | any,
      rawBehaviorEventMarkers: [] as any
    };
  },
  computed: {
    vehicles() {
      return this.$data.lodashFilter(this.$store.state.vehicles, {
        dongleActive: true
      });
    },
    tripRawEvents() {
      if (
        this.$store.state.routeHistory &&
        !isEmpty(this.$store.state.routeHistory) &&
        this.routeHistoryMode === RouteHistoryMode.RAW
      ) {
        // Preprocessing raw events to carry forward coordinates in events
        let tripRawEvents = this.$store.state.routeHistory.tripDetailEvents;
        tripRawEvents = helpers.carryForwardEvents(tripRawEvents);
        return tripRawEvents;
      } else {
        return undefined;
      }
    },
    animationControlsWidth() {
      // calculating width of the animation control bar
      // where 200 is the width of the sidebar and window.innerWidth * 0.3
      // is the width off the overlay
      return window.innerWidth - 200 - window.innerWidth * 0.3;
    },
    routeHistory() {
      const routeHistory = this.$store.state.routeHistory;
      if (routeHistory && this.routeHistoryMode === RouteHistoryMode.TRIP) {
        for (const key of Object.keys(routeHistory)) {
          for (let i = 0; i < routeHistory[key]["routes"].length; i++) {
            // carry forward events
            routeHistory[key]["routes"][
              i
            ].tripDetailEvents = helpers.carryForwardEvents(
              routeHistory[key]["routes"][i].tripDetailEvents
            );
          }
        }
      }
      return routeHistory;
    },
    routeHistoryUnfiltered() {
      const routeHistory = this.$store.state.routeHistoryUnfiltered;
      if (routeHistory && this.routeHistoryMode === RouteHistoryMode.TRIP) {
        for (const key of Object.keys(routeHistory)) {
          for (let i = 0; i < routeHistory[key]["routes"].length; i++) {
            // carry forward events
            routeHistory[key]["routes"][
              i
            ].tripDetailEvents = helpers.carryForwardEvents(
              routeHistory[key]["routes"][i].tripDetailEvents
            );
          }
        }
      }
      return routeHistory;
    },
    selectedTenant() {
      return this.$store.state.selectedTenant;
    },
    selectedVehicle() {
      return this.$data.query.selectedVehicle;
    }
  },
  watch: {
    selectedVehicle: function () {
      this.removeAllPaths();
    },
    routeHistoryMode: function () {
      this.removeAllPaths();
    },
    selectedTenant: async function () {
      await this.getVehicles();
      // if default location cannot be fetched show Munich
      await this.loadMap(
        {
          lat: 48.1351,
          lng: 11.582
        },
        12
      );
      if (
        this.$route.query.vehicleId &&
        this.$route.query.mode &&
        this.$route.query.date
      ) {
        this.submitQuery();
      }
    },
    vehicles: function () {
      this.filteredVehicles = this.vehicles;
    },
    tripRawEvents: async function () {
      if (this.tripRawEvents) {
        const google: any = await gmapsInit();
        // empty behavior events
        this.rawBehaviorEventMarkers = [];
        for (let i = 0; i < this.tripRawEvents.length; i++) {
          if (this.tripRawEvents[i].BEHAVE_ID !== null) {
            const markerIcon = this.fetchBehaviorIcon(
              this.tripRawEvents[i].BEHAVE_ID
            );
            const marker = new google.maps.Marker({
              position: {
                lat: this.tripRawEvents[i].LATITUDE,
                lng: this.tripRawEvents[i].LONGITUDE
              },
              // if marker contains behavior, source or destination location set on map
              map: this.map,
              icon: {
                url: require("@/assets/img/" + markerIcon.icon),
                scaledSize: new google.maps.Size(40, 40)
              }
            });
            this.rawBehaviorEventMarkers.push(marker);
          }
        }
      }
    }
  },
  beforeDestroy() {
    if (this.routeHistory) {
      this.$store.dispatch("clearRouteHistory");
    }
    this.stopAnimationInBackground();
  },
  async created() {
    if (this.$route.query.vehicleId) {
      this.query.selectedVehicle = this.$route.query.vehicleId.toString();
    }
    if (this.$route.query.mode) {
      this.routeHistoryMode = this.$route.query.mode.toString() as RouteHistoryMode;
    }
    // if url has date param
    if (this.$route.query.date) {
      this.query.date = new Date(this.$route.query.date.toString());
    }
    if (this.vehicles) {
      this.filteredVehicles = this.vehicles;
    }
  },
  methods: {
    exportEvents() {
      const csvFormattedDongleEvents = this.routeHistory.tripDetailEvents.map(
        e => {
          return {
            recordedAt: DateTime.fromISO(e.recordedAt).toFormat(
              "dd/MM/yyyy, hh:mm:ss a"
            ),
            receivedAt: DateTime.fromISO(e.receivedAt).toFormat(
              "dd/MM/yyyy, hh:mm:ss a"
            ),
            LATTITUDE: e.LATITUDE,
            LONGITUDE: e.LONGITUDE,
            GPS_SPEED: e.GPS_SPEED
          };
        }
      );
      const csvExportOptions = {
        rename: ["Recorded At", "Received At", "Latitude", "Longitude", "Speed"]
      };
      jsonexport(csvFormattedDongleEvents, csvExportOptions, (err, csv) => {
        if (err) {
          notification.error("Error: Unable to export file");
          return console.error(err);
        }
        if (csv) {
          // if export file exist download it
          // creating csv file link from blob
          // and simulating click to download
          const url = URL.createObjectURL(
            new Blob([csv], {
              type: "text/csv"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          // creating file name to include vehicle and date
          const vehicleName =
            find(this.vehicles, {
              id: this.query.selectedVehicle
            }).nickname +
            " - " +
            find(this.vehicles, {
              id: this.query.selectedVehicle
            }).registrationNo;
          const queryDate = DateTime.fromJSDate(this.query.date).toFormat(
            "dd LLL yyyy"
          );
          const filename =
            "Dongle Events - " + vehicleName + " - " + queryDate + ".csv";

          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    startTripReplay() {
      if (this.tripReplay.animationComplete === true) {
        this.tripReplay.animationComplete = false;
        this.tripReplay.animationIterator = -1;
      }
      this.tripReplay.animationActive = true;
      if (this.tripReplay.vehicleMarker && this.map) {
        this.tripReplay.vehicleMarker.setMap(this.map);
        this.startAnimationInBackground();
      }
    },
    startAnimationInBackground() {
      if (this.tripReplay.backgroundRefresh) {
        clearInterval(this.tripReplay.backgroundRefresh);
      }
      this.tripReplay.backgroundRefresh = setInterval(
        function (this: any) {
          this.animateVehicleMarker();
        }.bind(this),
        this.tripReplay.playbackInterval
      );
    },
    stopAnimationInBackground() {
      this.tripReplay.animationActive = false;
      if (this.tripReplay.backgroundRefresh) {
        clearInterval(this.tripReplay.backgroundRefresh);
      }
    },
    animateVehicleMarker() {
      // check if animation iterator has reached the last event
      if (this.tripReplay.animationIterator === this.tripRawEvents.length - 1) {
        this.tripReplay.animationComplete = true;
        this.stopAnimationInBackground();
      } else {
        if (this.tripReplay.vehicleMarker && this.map) {
          this.tripReplay.animationIterator =
            this.tripReplay.animationIterator + 1;
          this.tripReplay.vehicleMarker.setPosition({
            lat: this.tripRawEvents[this.tripReplay.animationIterator].LATITUDE,
            lng: this.tripRawEvents[this.tripReplay.animationIterator].LONGITUDE
          });
        }
      }
    },
    removeBehaviorEventMarkers() {
      if (this.rawBehaviorEventMarkers.length) {
        for (let i = 0; i < this.rawBehaviorEventMarkers.length; i++) {
          this.rawBehaviorEventMarkers[i].setMap(null);
        }
        this.rawBehaviorEventMarkers = [];
      }
    },
    removeRawEventInfoWindow() {
      if (this.map) {
        if (this.rawEventInfoWindow) {
          this.rawEventInfoWindow.close();
        }
        if (this.rawEventMarker) {
          this.rawEventMarker.setMap(null);
        }
      }
    },
    async addRawEventInfoWindow(event: any) {
      const google: any = await gmapsInit();
      if (this.map) {
        this.removeRawEventInfoWindow();
        this.rawEventInfoWindow = new google.maps.InfoWindow();
        this.rawEventMarker = new google.maps.Marker({
          position: {
            lat: event.LATITUDE,
            lng: event.LONGITUDE
          },
          icon: {
            url: require("@/assets/img/map_marker.svg"),
            scaledSize: new google.maps.Size(20, 20)
          },
          map: this.map
        });
        this.rawEventInfoWindow.setContent(
          (event.BEHAVE_ID !== null
            ? this.fetchBehaviorIcon(parseInt(event.BEHAVE_ID) as behaveId)
              .title + "<br/ >"
            : "") +
          `Speed: ${event.GPS_SPEED ? event.GPS_SPEED.toFixed(2) + " km/h" : "-"
          }
            <br/>
            Lat: ${event.LATITUDE}
            <br/>
            Lng: ${event.LONGITUDE}
            <br/>
            Received At: ${this.$options.filters?.date(
            event.receivedAt,
            "time-date-seconds"
          )}
            <br/>
            Recorded At: ${this.$options.filters?.date(
            event.recordedAt,
            "time-date-seconds"
          )}`
        );
        this.rawEventInfoWindow.open(this.map, this.rawEventMarker);
      }
    },
    async createVehicleMarker() {
      const google: any = await gmapsInit();
      if (this.map) {
        this.tripReplay.vehicleMarker = new google.maps.Marker({
          position: {
            lat: 1,
            lng: 1
          },
          icon: {
            url: require("@/assets/img/car-marker.svg"),
            scaledSize: new google.maps.Size(50, 50)
          },
          map: null
        });
      }
    },
    fetchBehaviorIcon(behaviorId: number) {
      return helpers.fetchBehaviorIcon(behaviorId);
    },
    async getVehicles() {
      await this.$store
        .dispatch("getVehicles", { selectedTenant: this.selectedTenant })
        .then(() => {
          this.filteredVehicles = this.vehicles;
        });
    },
    filterVehicles() {
      this.filteredVehicles = this.vehicles;
      if (this.vehicles && this.filters.vehicleSearchQuery != "") {
        this.filteredVehicles = this.filteredVehicles?.filter(vehicles => {
          return (
            vehicles.registrationNo
              ?.toLowerCase()
              .includes(this.filters.vehicleSearchQuery!.toLowerCase()) ||
            vehicles.nickname
              ?.toLowerCase()
              .includes(this.filters.vehicleSearchQuery!.toLowerCase())
          );
        });
      }
    },
    prepareDateTime(date: Date, time: Date) {
      const res =
        DateTime.fromISO(date.toISOString()).toFormat("yyyy-MM-dd") +
        "T" +
        DateTime.fromISO(time.toISOString()).toFormat("hh:mm:ss.000") +
        "Z";
      return res;
    },
    clearQuery() {
      this.removeAllPaths();
      this.query.selectedVehicle = undefined;
      this.query.date = undefined;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          vehicleId: undefined,
          date: undefined,
          mode: undefined
        }
      });
    },
    async submitQuery() {
      this.removeAllPaths();
      if (this.query.selectedVehicle && this.query.date) {
        // set query params on route for state management
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            vehicleId: this.query.selectedVehicle,
            date:
              DateTime.fromISO(this.query.date.toISOString()).toFormat(
                "yyyy-MM-dd"
              ) + "T00:00:00.000Z",
            mode: this.routeHistoryMode
          }
        });
        // generate request
        const request = {
          selectedTenant: this.selectedTenant,
          vehicleId: this.query.selectedVehicle,
          date:
            // to format date into ISO, we add 00:00:00 in time part
            DateTime.fromISO(this.query.date.toISOString()).toFormat(
              "yyyy-MM-dd"
            ) + "T00:00:00.000Z",
          removeNoise: this.query.removeGpsNoise
        };
        this.isRouteLoading = true;
        if (this.routeHistoryMode === RouteHistoryMode.TRIP) {
          await this.$store.dispatch("getRouteHistory", request).then(() => {
            if (this.routeHistory) {
              this.drawRoutesOnMap();
            }
          });
        } else if (this.routeHistoryMode === RouteHistoryMode.RAW) {
          await this.$store
            .dispatch("getRawRouteHistory", request)
            .then(async () => {
              if (this.routeHistory) {
                await this.drawRawRoutesOnMap();
              }
            });
          await this.$store
            .dispatch("getRawRouteHistoryUnfiltered", request)
            .then(async () => {
              if (this.routeHistoryUnfiltered) {
                await this.drawUnfilteredRawRoutesOnMap();
              }
            });
        }
        this.isRouteLoading = false;
      }
    },
    selectTrip(tripId: string, enableFocus = false) {
      this.selectedTrip = tripId;
      for (let i = 0; i < this.tripPaths.length; i++) {
        this.setPathInactive(this.tripPaths[i]);
      }
      const selectedPath = lodash.find(this.tripPaths, {
        tripId: tripId
      });
      this.setPathActive(selectedPath);
      if (enableFocus) {
        // scroll to focus on the selected trip to view
        document.getElementById(tripId)?.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
    },
    async setPathActive(path: any) {
      const google: any = await gmapsInit();
      const indexMarkerIconActive = {
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        fillColor: this.pathColors.pathColorActive,
        strokeOpacity: 1,
        strokeWeight: 1,
        strokeColor: this.pathColors.pathBorderColorActive,
        scale: 12
      };
      path.setOptions({
        strokeColor: this.pathColors.pathColorActive,
        zIndex: 2
      });
      path.pathBorder.setOptions({
        strokeColor: this.pathColors.pathBorderColorActive,
        zIndex: 2
      });
      path.indexMarker.setOptions({ icon: indexMarkerIconActive, zIndex: 2 });
      //path.infoWindow.open(this.map, path.indexMarker);
      path.sourceMarker.setMap(this.map);
      path.destinationMarker.setMap(this.map);
    },
    async setPathInactive(path: any) {
      const google: any = await gmapsInit();
      const indexMarkerIconInactive = {
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        fillColor: this.pathColors.pathColorInactive,
        strokeOpacity: 1,
        strokeWeight: 1,
        strokeColor: this.pathColors.pathBorderColorInactive,
        scale: 12
      };
      path.setOptions({
        strokeColor: this.pathColors.pathColorInactive,
        zIndex: 1
      });
      path.pathBorder.setOptions({
        strokeColor: this.pathColors.pathBorderColorInactive,
        zIndex: 1
      });
      path.indexMarker.setOptions({ icon: indexMarkerIconInactive, zIndex: 1 });
      path.infoWindow.close(this.map, path.indexMarker);
      path.sourceMarker.setMap(null);
      path.destinationMarker.setMap(null);
    },
    removeAllPaths() {
      this.removeRawEventInfoWindow();
      this.removeBehaviorEventMarkers();
      this.selectedTrip = undefined;
      for (let i = 0; i < this.tripPaths.length; i++) {
        this.tripPaths[i].setMap(null);
        this.tripPaths[i].pathBorder.setMap(null);
        if (
          this.tripPaths[i].sourceMarker &&
          this.tripPaths[i].destinationMarker
        ) {
          this.tripPaths[i].sourceMarker.setMap(null);
          this.tripPaths[i].destinationMarker.setMap(null);
        }
        if (this.tripPaths[i].indexMarker) {
          this.tripPaths[i].indexMarker.setMap(null);
        }
        if (this.tripReplay.vehicleMarker) {
          this.tripReplay.vehicleMarker.setMap(null);
        }
        this.tripReplay.animationActive = false;
        this.tripReplay.animationComplete = false;
        this.tripReplay.animationIterator = -1;
        this.stopAnimationInBackground();
      }
      this.tripPaths = [];
      // clear stored routes in vuex store
      this.$store.dispatch("clearRouteHistory");
    },
    async loadMap(center: { lat: number; lng: number }, zoom: number) {
      try {
        const google: any = await gmapsInit();
        const geocoder = new google.maps.Geocoder();
        this.map = new google.maps.Map(
          document.getElementById("map") as HTMLElement,
          {
            center: center,
            zoom: zoom,
            mapTypeControl: false
          }
        );
        this.createVehicleMarker();
      } catch (err) {
        notification.error(err.message);
      }
    },
    tripDuration(trip: Record<string, string>): string {
      const duration = Duration.fromObject(
        DateTime.fromISO(trip.endTime)
          .diff(DateTime.fromISO(trip.startTime), "hours")
          .toObject()
      );
      let formattedDuration = undefined;
      if (duration.hours > 1) {
        formattedDuration = duration.toFormat("hh Hr mm") + " Mins";
      } else {
        formattedDuration = duration.toFormat("mm") + " Mins";
      }
      return formattedDuration;
    },
    getInfoWindowContent(trip: any) {
      let contentString = "";
      contentString += "<p>Duration:<br>" + this.tripDuration(trip) + "</p>";

      return contentString;
    },
    async drawRoutesOnMap() {
      if (!lodash.isEmpty(this.routeHistory)) {
        const google: any = await gmapsInit();
        const bounds = new google.maps.LatLngBounds();
        for (const key of Object.keys(this.routeHistory)) {
          for (let i = 0; i < this.routeHistory[key]["routes"].length; i++) {
            const pathCoordinates = this.routeHistory[key]["routes"][
              i
            ].tripDetailEvents.map((event: any) => ({
              lat: event.LATITUDE,
              lng: event.LONGITUDE
            }));
            const indexMarkerIcon = {
              path: google.maps.SymbolPath.CIRCLE,
              fillOpacity: 1,
              fillColor: this.pathColors.pathColorInactive,
              strokeOpacity: 1,
              strokeWeight: 1,
              strokeColor: this.pathColors.pathBorderColorInactive,
              scale: 12
            };
            const indexMarker = new google.maps.Marker({
              map: this.map,
              // get the event at the center of the array
              position: pathCoordinates[Math.floor(pathCoordinates.length / 2)],
              title: this.routeHistory[key]["routes"][i].index.toString(),
              icon: indexMarkerIcon,
              label: {
                color: "#FFF",
                fontSize: "12px",
                fontWeight: "600",
                text: this.routeHistory[key]["routes"][i].index.toString()
              }
            });
            const infoWindow = new google.maps.InfoWindow({
              content: this.getInfoWindowContent(
                this.routeHistory[key]["routes"][i]
              )
            });
            const sourceMarker = new google.maps.Marker({
              position: {
                lat: this.routeHistory[key]["routes"][i].sourceLatitude,
                lng: this.routeHistory[key]["routes"][i].sourceLongitude
              },
              icon: {
                url: require("@/assets/img/location-pin-a.png"),
                scaledSize: new google.maps.Size(40, 40)
              }
            });
            const destinationMarker = new google.maps.Marker({
              position: {
                lat: this.routeHistory[key]["routes"][i].destinationLatitude,
                lng: this.routeHistory[key]["routes"][i].destinationLongitude
              },
              icon: {
                url: require("@/assets/img/location-pin-b.png"),
                scaledSize: new google.maps.Size(40, 40)
              }
            });
            const pathBorder = new google.maps.Polyline({
              path: pathCoordinates,
              geodesic: true,
              strokeColor: this.pathColors.pathBorderColorInactive,
              strokeOpacity: 1,
              strokeWeight: 8,
              map: this.map,
              zIndex: 1,
              tripId: this.routeHistory[key]["routes"][i].id
            });
            const path = new google.maps.Polyline({
              path: pathCoordinates,
              geodesic: true,
              strokeColor: this.pathColors.pathColorInactive,
              strokeOpacity: 1,
              strokeWeight: 5,
              map: this.map,
              zIndex: 1,
              tripId: this.routeHistory[key]["routes"][i].id,
              // storing borders and marker in trip path
              // for manipulation later
              pathBorder: pathBorder,
              sourceMarker: sourceMarker,
              destinationMarker: destinationMarker,
              indexMarker: indexMarker,
              infoWindow: infoWindow
            });
            // adding listeners to enable functionality to click on the paths drawn on map
            // clicking on paths selects the trip from the trips display on left side
            path.addListener("mousedown", () => {
              this.selectTrip(this.routeHistory[key]["routes"][i].id, true);
            });
            path.pathBorder.addListener("mousedown", () => {
              this.selectTrip(this.routeHistory[key]["routes"][i].id, true);
            });
            path.indexMarker.addListener("mousedown", () => {
              this.selectTrip(this.routeHistory[key]["routes"][i].id, true);
            });
            this.tripPaths?.push(path);
            for (let j = 0; j < pathCoordinates.length; j++) {
              // extend bounds by adding all points of polyline
              bounds.extend(pathCoordinates[j]);
            }
          }
        }
        this.map.fitBounds(bounds, {
          left: window.innerWidth * 0.2
        });
      }
    },
    async drawRawRoutesOnMap() {
      if (!lodash.isEmpty(this.routeHistory)) {
        const google: any = await gmapsInit();
        const bounds = new google.maps.LatLngBounds();

        const pathCoordinates = this.routeHistory.tripDetailEvents.map(
          (event: any) => ({
            lat: event.LATITUDE,
            lng: event.LONGITUDE
          })
        );

        const sourceMarker = new google.maps.Marker({
          position: {
            lat: this.routeHistory.tripDetailEvents[0].LATITUDE,
            lng: this.routeHistory.tripDetailEvents[0].LONGITUDE
          },
          icon: {
            url: require("@/assets/img/location-pin-a.png"),
            scaledSize: new google.maps.Size(40, 40)
          },
          map: this.map
        });
        const destinationMarker = new google.maps.Marker({
          position: {
            lat: this.routeHistory.tripDetailEvents[
              this.routeHistory.tripDetailEvents.length - 1
            ].LATITUDE,
            lng: this.routeHistory.tripDetailEvents[
              this.routeHistory.tripDetailEvents.length - 1
            ].LONGITUDE
          },
          icon: {
            url: require("@/assets/img/location-pin-b.png"),
            scaledSize: new google.maps.Size(40, 40)
          },
          map: this.map
        });
        const pathBorder = new google.maps.Polyline({
          path: pathCoordinates,
          geodesic: true,
          strokeColor: this.pathColors.pathBorderColorActive,
          strokeOpacity: 1,
          strokeWeight: 2,
          map: this.map,
          zIndex: 2,
          tripId: this.routeHistory.tripDetailEvents.id
        });
        const path = new google.maps.Polyline({
          path: pathCoordinates,
          geodesic: true,
          strokeColor: this.pathColors.pathColorActive,
          strokeOpacity: 1,
          strokeWeight: 1,
          map: this.map,
          zIndex: 2,
          tripId: this.routeHistory.tripDetailEvents.id,
          // storing borders and marker in trip path
          // for manipulation later
          pathBorder: pathBorder,
          sourceMarker: sourceMarker,
          destinationMarker: destinationMarker
        });
        if (this.routeHistoryMode === RouteHistoryMode.RAW) {
          // adding listeners to enable functionality to click on the paths drawn on map
          // clicking on paths displays the nearest event info window
          path.addListener("mousedown", (event: any) => {
            this.displayNearestEventInfoWindow({
              lat: event.latLng.lat() as number,
              lng: event.latLng.lng() as number
            });
          });
          path.pathBorder.addListener("mousedown", (event: any) => {
            this.displayNearestEventInfoWindow({
              lat: event.latLng.lat() as number,
              lng: event.latLng.lng() as number
            });
          });
        }
        this.tripPaths?.push(path);
        for (let j = 0; j < pathCoordinates.length; j++) {
          // extend bounds by adding all points of polyline
          bounds.extend(pathCoordinates[j]);
        }

        this.map.fitBounds(bounds, {
          left: window.innerWidth * 0.2,
          bottom: window.innerHeight * 0.1
        });
      }
    },
    async drawUnfilteredRawRoutesOnMap() {
      if (!lodash.isEmpty(this.routeHistoryUnfiltered)) {
        const google: any = await gmapsInit();
        const bounds = new google.maps.LatLngBounds();

        const pathCoordinates = this.routeHistoryUnfiltered.tripDetailEvents.map(
          (event: any) => ({
            lat: event.LATITUDE,
            lng: event.LONGITUDE
          })
        );

        // const sourceMarker = new google.maps.Marker({
        //   position: {
        //     lat: this.routeHistoryUnfiltered.tripDetailEvents[0].LATITUDE,
        //     lng: this.routeHistoryUnfiltered.tripDetailEvents[0].LONGITUDE
        //   },
        //   icon: {
        //     url: require("@/assets/img/location-pin-a.png"),
        //     scaledSize: new google.maps.Size(40, 40)
        //   },
        //   map: this.map
        // });
        // const destinationMarker = new google.maps.Marker({
        //   position: {
        //     lat: this.routeHistoryUnfiltered.tripDetailEvents[
        //       this.routeHistoryUnfiltered.tripDetailEvents.length - 1
        //     ].LATITUDE,
        //     lng: this.routeHistoryUnfiltered.tripDetailEvents[
        //       this.routeHistoryUnfiltered.tripDetailEvents.length - 1
        //     ].LONGITUDE
        //   },
        //   icon: {
        //     url: require("@/assets/img/location-pin-b.png"),
        //     scaledSize: new google.maps.Size(40, 40)
        //   },
        //   map: this.map
        // });
        const pathBorder = new google.maps.Polyline({
          path: pathCoordinates,
          geodesic: true,
          strokeColor: this.pathColors.pathBorderColorUnfiltered,
          strokeOpacity: 1,
          strokeWeight: 8,
          map: this.map,
          zIndex: 1,
          tripId: this.routeHistoryUnfiltered.tripDetailEvents.id
        });
        const path = new google.maps.Polyline({
          path: pathCoordinates,
          geodesic: true,
          strokeColor: this.pathColors.pathColorUnfiltered,
          strokeOpacity: 1,
          strokeWeight: 5,
          map: this.map,
          zIndex: 1,
          tripId: this.routeHistoryUnfiltered.tripDetailEvents.id,
          // storing borders and marker in trip path
          // for manipulation later
          pathBorder: pathBorder
          //   sourceMarker: sourceMarker,
          //   destinationMarker: destinationMarker
        });
        if (this.routeHistoryUnfilteredMode === RouteHistoryMode.RAW) {
          // adding listeners to enable functionality to click on the paths drawn on map
          // clicking on paths displays the nearest event info window
          path.addListener("mousedown", (event: any) => {
            this.displayNearestEventInfoWindow({
              lat: event.latLng.lat() as number,
              lng: event.latLng.lng() as number
            });
          });
          path.pathBorder.addListener("mousedown", (event: any) => {
            this.displayNearestEventInfoWindow({
              lat: event.latLng.lat() as number,
              lng: event.latLng.lng() as number
            });
          });
        }
        this.tripPaths?.push(path);
        for (let j = 0; j < pathCoordinates.length; j++) {
          // extend bounds by adding all points of polyline
          bounds.extend(pathCoordinates[j]);
        }

        this.map.fitBounds(bounds, {
          left: window.innerWidth * 0.2,
          bottom: window.innerHeight * 0.1
        });
      }
    },
    async displayNearestEventInfoWindow(coordinates: {
      lat: number;
      lng: number;
    }) {
      // takes coordinates as argument
      // checks against stored events to find the nearest event
      // displays the nearest event in info window on map
      if (this.routeHistory.tripDetailEvents) {
        const pathCoordinates = this.routeHistory.tripDetailEvents.map(
          (event: any) => ({
            lat: event.LATITUDE,
            lng: event.LONGITUDE
          })
        );
        // finding nearest event based on coordinate
        let closestCoordinates = pathCoordinates[0];
        let closestDistance = this.getDistance(closestCoordinates, coordinates);
        let closestIndex = 0;
        for (let i = 1; i < pathCoordinates.length; i++) {
          if (
            this.getDistance(pathCoordinates[i], coordinates) < closestDistance
          ) {
            closestDistance = this.getDistance(pathCoordinates[i], coordinates);
            closestCoordinates = pathCoordinates[i];
            closestIndex = i;
          }
        }
        this.addRawEventInfoWindow(
          this.routeHistory.tripDetailEvents[closestIndex]
        );
      }
    },
    getDistance(
      firstCoordinates: { lat: number; lng: number },
      secondCoordinates: { lat: number; lng: number }
    ) {
      // finds distance between two points using the Haversine formula
      // ref: https://stackoverflow.com/questions/29118745/get-nearest-latitude-and-longitude-from-array
      const lat1 = firstCoordinates.lat;
      const lat2 = secondCoordinates.lat;
      const lon1 = firstCoordinates.lng;
      const lon2 = secondCoordinates.lng;
      const R = 6371000; // metres
      const φ1 = (lat1 * Math.PI) / 180.0;
      const φ2 = (lat2 * Math.PI) / 180.0;
      const Δφ = ((lat2 - lat1) * Math.PI) / 180.0;
      const Δλ = ((lon2 - lon1) * Math.PI) / 180.0;

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const d = R * c;
      return d;
    }
  }
});
